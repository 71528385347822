/** @format */

import './style.css';

// Getting Dom Elements
const headerEl = document.querySelector('#header');
const subHeaderEl = document.querySelector('#sub-header');
const errorEl = document.querySelector('#error');
const loaderEl = document.querySelector('#loader');

// Constants
let apiURL;
if (window.location.href.split('.').includes('dev')) {
  apiURL = 'https://api.overwatch.dev.bureau.id/v1/suppliers/esign-redirect-url';
} else if (window.location.href.split('.').includes('stg')) {
  apiURL = 'https://api.overwatch.stg.bureau.id/v1/suppliers/esign-redirect-url';
} else {
  apiURL = 'https://api.overwatch.bureau.id/v1/suppliers/esign-redirect-url';
}
const urlParams = new URLSearchParams(window.location.search);
const documentId = urlParams.get('documentId');
const token = { token: documentId };

// Onload Function
window.addEventListener('load', () => {
  fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Basic MzNiNTQ0N2ItZGU2My00MTZjLTgzYTMtYjdhNTk5ZTVmYjU1OjFhZGQwMjAxLWZiODEtNDJjOS1hMzNiLTcxN2Y1ZWVkNWVmNg==',
    },
    body: JSON.stringify(token),
  })
    .then(r => r.json())
    .then(data => {
      if (data.statusCode === 200) {
        window.location.href = data.redirectUrl;
      } else {
        headerEl.classList.add('hidden');
        subHeaderEl.classList.add('hidden');
        loaderEl.classList.add('hidden');
        errorEl.innerHTML = data.error.description;
      }
    })
    .catch(error => {
      headerEl.classList.add('hidden');
      subHeaderEl.classList.add('hidden');
      loaderEl.classList.add('hidden');
      errorEl.innerHTML = error;
    });
});
